import React from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      company: "",
      email: "",
      message: "",
    },
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handlePost = (formData, event) => {
    fetch(`/`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...formData }),
    })
      .then(response => {
        reset()
        toast.success("Sua mensagem foi enviada, agradecemos o contato!", {
          position: "bottom-right",
          theme: "colored",
        })
      })
      .catch(error => {
        toast.error(
          "Ops! Ocorreu um erro ao enviar a mensagem, tente novamente mais tarde.",
          {
            position: "bottom-right",
            theme: "colored",
          }
        )
      })
    event.preventDefault()
  }

  return (
    <div className="min-w-screen min-h-screen flex bg-cta-img bg-no-repeat bg-contain bg-center h-auto px-10 -z-1">
      <div className="flex flex-col w-full p5-3 md:px-10 md:pl-10 mx-auto space-y-6 h-auto md:mb-20 md:flex-row md:items-center md:space-x-6 items-center justify-center">
        <div className="w-full md:w1/2 items-center">
          <h1 className="text-3xl md:text-5xl leading-normal mt-0 mb-2 text-black text-center flex flex-col">
            <span className="mb-2 font-bold">Solicite</span>
            <span>uma demonstração</span>
          </h1>
        </div>
        <div className="w-full md:w1/2 items-center">
          <form
            className="w-full max-w-lg relative bg-transparent shadow-lg px-3 py-5 mb-4"
            data-netlify="true"
            netlify-honeypot="shall-not-pass"
            name="contact-form"
            method="post"
            onSubmit={handleSubmit(handlePost)}
          >
            <input
              type="hidden"
              name="form-name"
              value="contact-form"
              {...register("form-name")}
            />
            <input
              type="hidden"
              name="formId"
              value="contact-form"
              {...register("formId")}
            />
            <p className="hidden">
              <label>
                Don’t fill this out if you’re human:{" "}
                <input name="shall-not-pass" {...register("shall-not-pass")} />
              </label>
            </p>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full flex flex-col justify-center items-center p-3 z-10">
                <div
                  className={`outline relative border-2 border-gray-200 focus-within:border-blue-500 rounded-md w-full`}
                >
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder=" "
                    {...register("name", { required: true })}
                    className="block p-4 w-full text-md appearance-none focus:outline-none bg-transparent"
                  />
                  <label
                    htmlFor="name"
                    className="absolute top-0 text-md bg-transparent text-black font-bold p-4 z-0 duration-300 origin-0"
                  >
                    Nome:
                  </label>
                </div>
                {errors.name && (
                  <p className="text-red-500 text-xs italic">
                    Informe seu nome
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full flex flex-col justify-center items-center p-3">
                <div
                  className={`outline relative border-2 border-gray-200 focus-within:border-blue-500 rounded-md w-full`}
                >
                  <input
                    id="company"
                    name="company"
                    type="text"
                    placeholder=" "
                    {...register("company", { required: true })}
                    className="block p-4 w-full text-md appearance-none focus:outline-none bg-transparent"
                  />
                  <label
                    htmlFor="company"
                    className="absolute top-0 text-md bg-transparent text-black font-bold p-4 z-0 duration-300 origin-0"
                  >
                    Empresa:
                  </label>
                </div>
                {errors.company && (
                  <p className="text-red-500 text-xs italic">
                    Informe o nome da sua empresa
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full flex flex-col justify-center items-center p-3 z-10">
                <div
                  className={`outline relative border-2 border-gray-200 focus-within:border-blue-500 rounded-md w-full`}
                >
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder=" "
                    {...register("email", {
                      required: true,
                      pattern:
                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                    })}
                    className="block p-4 w-full text-md appearance-none focus:outline-none bg-transparent"
                  />
                  <label
                    htmlFor="email"
                    className="absolute top-0 text-md bg-transparent text-black font-bold p-4 z-0 duration-300 origin-0"
                  >
                    E-mail:
                  </label>
                </div>
                {errors.email && (
                  <p className="text-red-500 text-xs italic">
                    Informe um endereço de e-mail válido
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full flex flex-col justify-center items-center p-3 z-10">
                <div
                  className={`outline relative border-2 border-gray-200 focus-within:border-blue-500 rounded-md w-full`}
                >
                  <textarea
                    id="message"
                    type="message"
                    name="message"
                    placeholder=" "
                    {...register("message", {
                      required: true,
                    })}
                    className="block p-4 w-full no-resize text-md appearance-none focus:outline-none bg-transparent h-48 resize-none"
                  />
                  <label
                    htmlFor="name"
                    className="absolute top-0 text-md bg-transparent text-black font-bold p-4 z-0 duration-300 origin-0"
                  >
                    Mensagem:
                  </label>
                </div>
                {errors.message && (
                  <p className="text-red-500 text-xs italic">
                    Fale um pouco mais sobre as suas necessidades
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                className="shadow bg-orange hover:bg-orange-light text-white py-2 px-4 rounded"
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
